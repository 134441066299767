import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {useEffect, useState} from 'react';
import auctionServices from '../../../../services/auction-services.js';
import Event from '../event/event.jsx';
import EventBrowseItems from '../EventBrowseItems/EventBrowseItems.jsx';
import { mobileStyle } from '../../Home.jsx';
import EventFlashSales from '../EventFlashSale/EventFlashSale.jsx';

function EventPage() {
    const [isEventLoadng,setIsEventLoading] = useState(true);
    const  [uiType,setUiType] = useState('')
    const search = useLocation().search;
    const item_type = new URLSearchParams(search).get('item-type');
    const navigate = useNavigate()
    let showIncomplete = new URLSearchParams(search).get('showIncomplete') || new URLSearchParams(search).get('admin_preview');
    const { auctionId } = useParams()


    const fetchAuctionById = async () => {
        try {
            setIsEventLoading(true)
            const  data  = await auctionServices.getAuctionById(auctionId, showIncomplete ? true : false, true); /// false is for showIncomplete, and true is for getCauseImage
            if(data.msg == 'Event coming soon'){
                navigate('/coming-soon',{state:{image1:data?.image1,image2:data?.image2,title:data?.name,start_date:data?.start_date, market_place: data?.market_place, is_store: data?.is_store}}, {replace: true})
                return
              }
            // setUiType("flash_sale")
            setUiType(data?.data?.ui)
            console.log(data?.data?.ui, 'aucthion data')
        } catch (error) { }
        finally {
            setIsEventLoading(false)
        }
    }

    useEffect(()=>{
        fetchAuctionById()
    },[])

    let component =<div>Loading</div>
    switch (uiType) {
        case "default":
            component = item_type ? <EventBrowseItems backgroundColor={mobileStyle.backgroundColor[4]} /> : <Event />
            // component = <EventBrowseItems backgroundColor={mobileStyle.backgroundColor[4]} />
            break;
        case "flash_sale":
            component = <EventFlashSales backgroundColor={mobileStyle.backgroundColor[4]}/>
            break;
        default:
            component = item_type ? <EventBrowseItems backgroundColor={mobileStyle.backgroundColor[4]} /> : <Event />
            // component = <EventBrowseItems backgroundColor={mobileStyle.backgroundColor[4]} />
            break;
    }

    return (
        <>
         {component}
        </>
    )
}

export default EventPage;