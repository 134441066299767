import { useEffect, useRef, useState } from "react";
import ImageSkelton from "../ImageSkelton/ImageSkelton";
import classess from "./newProductCardForFlashSale.module.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { getDiscount, getDisplayListPrice, getDisplayPrice } from "../../../../utils/displayPrice";

const getCauseAmount = (item) => {
  let amt = 0;
  const causeSharePercentage = item?.donor?.revenue_splits
    ? +item?.donor?.revenue_splits[0]?.cause_rev_share_percent / 100 || 0.8
    : 0.8;
  if (item?.store_item_id) {
    amt = Number((getDisplayPrice(item)-getDiscount(item) )* causeSharePercentage);
  } else {
    if (item?.highestBid?.length) {
      amt = Number(item?.highestBid[0]?.max_bid * causeSharePercentage);
    } else if (item?.buy_now_price) {
      amt = Number(item?.buy_now_price * causeSharePercentage);
    } else {
      amt = Number(item?.price * causeSharePercentage);
    }
  }
  return amt;
};

export default function NewProductCardForFlashSale({
  item,
  image,
  needXref = true,
  handleClick,
}) {
  const elemRef = useRef();
  const navigate = useNavigate();
  const [imageLoading, setImageLoading] = useState(true);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  useEffect(() => {
    (() => {
      // let price = item?.store_item?.price;
      // let listPrice = item?.store_item?.list_price;
      // let price = item?.store_item?.discounted_price !== undefined && item?.store_item?.discounted_price !== null ? item?.store_item?.discounted_price : item?.store_item?.price;
      let price = getDisplayPrice(item);
      // let listPrice = item?.store_item?.list_price !== undefined && item?.store_item?.list_price !== null ? item?.store_item?.list_price : item?.store_item?.price;
      let listPrice = getDisplayListPrice(item);
      // console.log("This is item", item.id)
      // console.log("This is item.storeitem", item.store_item)
      if (!listPrice) return;
      setDiscountPercentage(
        Math.round(((+listPrice - +price) / +listPrice) * 100)
      );
    })();
  }, []);
  const handleNavigateClick = () => {
    // navigate(`/lot/${item?.id}?auction_id=${item?.auction_id}&cause_id=${item?.auction?.cause_id}`)
    if (elemRef.current) {
      localStorage.setItem(
        "browseScroll",
        elemRef.current.offsetTop - elemRef.current.scrollTop
      );
    }
    let xrefAuctionId;
    let cause_id = item?.auction?.cause_id;
    if (item?.auction_auction_item_xrefs?.length && needXref) {
      xrefAuctionId = item?.auction_auction_item_xrefs[0]?.auction_id;
      cause_id = item?.auction_auction_item_xrefs[0]?.auction?.cause?.id;
      navigate(
        `/lot/${item?.id}?auction_id=${item?.auction_id}&cause_id=${cause_id}&xref_auction_id=${xrefAuctionId}`
      );
    } else {
      navigate(
        `/lot/${item?.id}?auction_id=${item?.auction_id}&cause_id=${cause_id}`
      );
    }
  };

  const finalDiscountedPrice = Number(getDisplayPrice(item)).toFixed(2)
  const finalListPrice = getDisplayListPrice(item)

  console.log("This is item",item)

  return (
    <div
      ref={elemRef}
      className={classess.product_card}
      onClick={handleClick ? handleClick : handleNavigateClick}
    >
      <div className={classess.product_image}>
        <img
          src={
            image ||
            item?.auction_item_assets?.find(
              (img) => img.context_type === "portrait"
            )?.name
          }
          alt=""
          onLoad={() => setImageLoading(false)}
          style={{ display: imageLoading ? "none" : "block" }}
        />
        <ImageSkelton
          loadingState={imageLoading}
          style={{ height: "100%", aspectRatio: "auto" }}
        />
        {/* {discountPercentage ? (
          <span className={classess.discount_tag}>-{discountPercentage}%</span>
        ) : null} */}
        {item?.auction_item_amount_given_dialog === 1 ? (
          <div className={classess.donation_impact}>
            <span className={classess.impact_label}>[DONATION IMPACT]</span>
            <span className={classess.impact_amount}>
              ${getCauseAmount(item).toFixed(2)}
            </span>
          </div>
        ) : null}
      </div>

      <div className={classess.product_info}>
        <div className={classess.live_now_container}>
          <h3 className={classess.product_name}>{item.donor?.org_name}</h3>
          {/* {moment(item.auction.start_date).isBefore(moment())?<div className={classess.live_now}>Live now</div>:<div>{moment().format('MM/DD/YY')}</div>} */}
        </div>
        <h3 className={classess.product_name}>{item?.name}</h3>
        <div className={classess.product_price}>

          { item?.store_item_id ?
          <>
          <span className={classess.original_price}>
            {finalListPrice - finalDiscountedPrice === 0 ? null : (finalListPrice ? `${finalListPrice} Retail` : null)}
          </span>
          <span className={classess.current_price}>
            ${finalDiscountedPrice} <span style={{ fontWeight: "400" }}>Boundless Price</span>
            {/* {discountPercentage ? (
              <span className={classess.discount_tag}>-{discountPercentage}%</span>
            ) : null} */}
          </span>
          <span>
            {item?.store_item?.quantity_in_stock !== 0 && item?.store_item &&
              ((item?.auction_auction_item_xrefs?.[0]?.auction?.discount_percentage || item?.auction?.discount_percentage) &&
                <div className={classess.itemRedAlert}>
                  [Additional {item?.auction_auction_item_xrefs?.[0]?.auction?.discount_percentage
                    ? item?.auction_auction_item_xrefs[0].auction.discount_percentage
                    : item?.auction?.discount_percentage}% off given at checkout]
                </div>
              )
            }
          </span>
          </>
          :
            <div className={classess.priceContainer} >
              {
                item?.highestBid?.length ?
                  <div className={classess.listPriceContainer} >
                    <span className={classess.current_price} >
                      ${item?.highestBid[0]?.bid_amount}
                      <span style={{ fontWeight: "400" }}>Current Bid</span>
                    </span>
                    
                  </div>
                  :
                  <div className={classess.listPriceContainer} >
                    <span className={classess.current_price} >
                      ${item?.price}
                      <span style={{ fontWeight: "400" }}>Opening Bid</span>  
                    </span>
                    
                  </div>
              }
              {
                item?.buy_now_price &&
                <div className={classess.listPriceContainer} >
                  <span className={classess.current_price} >
                    ${item?.buy_now_price}
                    <span style={{ fontWeight: "400" }}>Buy Now</span>
                  </span>
                  
                </div>
              }
              {
                item?.status === 'sold' && item?.show_stock_availability === 1 &&
                <div className={classess.itemRedAlert}>
                  <span className='red_alert'>Sold</span>
                </div>
              }
              {
                item?.auction?.status === 'complete' &&
                <div className={classess.itemRedAlert}>
                  <span className='red_alert'>Event Closed</span>
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
}
