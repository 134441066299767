import React, { useContext, useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
// import HeaderV2 from '../../molecules/Header/HeaderV2';
import { mobileStyle } from "../../Home";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useMediaQuery as mdq } from '@mui/material';

import BrowseItemsBanner from "../../atoms/BrowseItemsBanner/BrowseItemsBanner";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import auctionServices from "../../../../services/auction-services";
import { useMediaQuery } from "react-responsive";
import {
  ChevronLeft,
  ChevronRight,
  CloseRounded,
  KeyboardArrowRight,
} from "@mui/icons-material";
import NewFooter from "../../molecules/NewFooter/NewFooter";
import BrowsePagesCardSection from "../../molecules/BrowsePagesCardSection/BrowsePagesCardSection";
import { Button, SwipeableDrawer } from "@mui/material";
import classes from "./EventFlashsale.module.css";
import {
  changePageNumber,
  clearCategoryDetails,
  clearSearchText,
  resetItems,
  setContextFrom,
  setSearchText,
  setSelectedCategory,
  clearAttribute,
} from "../../../../redux/itemsReducer";
import {
  getImageVariant,
  getImageWithoutVariant,
} from "../../../../utils/util-functions";
import downArrow from "../../../../images/downArrow.svg";
import FilterScreen from "../../molecules/FilterScreen/FilterScreen";
import { FilterContext } from "../../../../store/filter-context";
import BrowseItemsCauseSection from "../../molecules/BrowseItemsCauseSection/BrowseItemsCauseSection";
import { Desktop, Mobile } from "../../responsive/Responsive";
import LoadingScreen from "../../LoadingScreen/Loading";
import ESMBannerPart from "../../molecules/ESMBannerPart/ESMBannerPart";
import {
  clearBrowseBySelectedFilters,
  clearAllBrowseBySelectedFilters,
  getBrowseItemsProducts,
  getGroupedBrowseItems,
  resetBrowseItems,
} from "../../../../redux/browseItemsReducer";
import AdminPreviewBanner from "../../atoms/AdminPreviewBanner/adminPreviewBanner";
import NewButton from "../../atoms/NewButton/NewButton";
import SearchInput from "../../molecules/SearchInput/SearchInput";
import AuthContext from "../../../../store/auth-context";
import { config } from "../../../../config/config";
import NewProductCard from "../../molecules/NewProductCard/NewProductCard";
import HeaderV3 from "../../molecules/Header/HeaderV3/HeaderV3";
import PaginationV2 from "../../molecules/PaginationV2/PaginationV2";
import EventPageDetailsCard from "../../molecules/EventPageDetailsCard/EventPageDetailsCard";
import donorServices from "../../../../services/donor-services";
import BrowseByDropDown from "../../atoms/BrowseByDropDown/BrowseByDropDown";
import NewProductCardForFlashSale from "../../molecules/NewProductCardForFlashSale/NewProductCardForFlashSale";
import CountdownTimer from "../../molecules/CountDown/CountDown";
import CountdownTimerScroll from "../../molecules/CountDown/CountDownScroll";
const { REACT_APP_URL } = config;

const EventFlashSales = ({
  backgroundColor = mobileStyle.backgroundColor[2],
}) => {
  const scrollContainerRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isEventLoading, setIsEventLoading] = useState(false);
  const [oldEventImage, setOldEventImage] = useState(false);
  const [eventData, setEventData] = useState({});
  const [categoryData, setCategoryData] = useState({});
  const [isFilterScreenOpen, setIsFilterScreenOpen] = useState(false);
  const [isSortByScreenOpen, setIsSortByScreenOpen] = useState(false);
  const [eventCategories, setEventCategories] = useState([]);
  const [eventCustomCategories, setEventCustomCategories] = useState([]);
  const [clickedFilter, setClickedFilter] = useState("");
  const [filterChanging, setFilterChanging] = useState(false);
  const [noLoading, setNoLoading] = useState(false);
  const {
    sortBy,
    groupBy,
    setGroupBy,
    setSortBy,
    priceRange,
    handlePriceChange,
    reset,
    setContextFilterd,
    contextFilterd,
  } = useContext(FilterContext);
  const { isLoggedIn, userId } = useContext(AuthContext);
  const {
    pageNumber: page,
    selectedCategory,
    categoryName,
    categoryType,
    searchText,
    customCategoryHeading,
    sliceIndex,
    showSearchBar,
  } = useSelector((state) => state.items);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auctionId } = useParams();
  const location = useLocation().pathname;
  const search = useLocation().search;
  const {
    itemList,
    totalCount,
    totalPage,
    loading,
    selectedBrowseEventFilterObj,
    selectedBrowseEventFilter,
  } = useSelector((state) => state.browseItems);
  let itemType = new URLSearchParams(search).get("item-type");
  let categoryId = new URLSearchParams(search).get("category");
  let showIncomplete =
    new URLSearchParams(search).get("showIncomplete") ||
    new URLSearchParams(search).get("admin_preview");
  let adminPreview = showIncomplete ? true : false;
  let params = useParams();
  let itemFlag = itemType;
  /// Here categoryId can be cause-category-id, category-id or custom-category-id according to itemType from url.
  if (itemType === "browse-category" || itemType === "cause-category") {
    categoryId = params?.categoryId;
  }
  if (location.includes("marketplace")) {
    itemType = "marketplace";
  }
  if (location.includes("store")) {
    itemType = "store";
  }
  const forMobile = useMediaQuery({
    maxWidth: 768,
  });
  const isTablet = useMediaQuery({
    minWidth: 1080,
  });
  const limit = 60;
  let timout;
  // let sliceIndexInitial = 4;
  // let sliceIndexFinal = 3;

  const [sliceIndexInitial, setSliceIndexInitial] = useState(4);
  const [sliceIndexFinal, setSliceIndexFinal] = useState(3);
  const [eventDonors, setEventDonors] = useState([]);
  const [showNewTimer, setShowNewTimer] = useState(false);

  let scroller;
  const scrollToPosition = () => {
    const pos = JSON.parse(localStorage.getItem("browseScroll"));
    if (pos && !loading) {
      scroller = setTimeout(() => {
        window.scrollTo({ top: pos, behavior: "smooth" });
      }, 0);
      localStorage.removeItem("browseScroll");
    }
  };
  const fetchData = async () => {
    try {
      setNoLoading(false);
      dispatch(
        getBrowseItemsProducts({
          auctionId: auctionId,
          page: page,
          limit: limit,
          sortBy: sortBy,
          priceRangeStart: priceRange[0],
          priceRangeEnd: priceRange[1],
          itemType: "marketplace",
          categoryId: categoryId,
          itemFlag: null,
          searchText: searchText,
          userId: userId,
          adminPreview: adminPreview,
        })
      );
      setIsFilterScreenOpen(false);
      setIsSortByScreenOpen(false);
      setContextFilterd(true);
    } catch (error) { }
  };

  useEffect(() => {
    if (itemList?.length > 0) {
      scrollToPosition();
    }
  }, [itemList]);

  const fetchAuctionById = async () => {
    try {
      setIsEventLoading(true);
      const data = await auctionServices.getAuctionById(
        auctionId,
        showIncomplete ? true : false,
        true
      ); /// false is for showIncomplete, and true is for getCauseImage
      if (data.msg == "Event coming soon") {
        navigate(
          "/coming-soon",
          {
            state: {
              image1: data?.image1,
              image2: data?.image2,
              title: data?.name,
              start_date: data?.start_date,
              market_place: data?.market_place,
              is_store: data?.is_store,
            },
          },
          { replace: true }
        );
        return;
      }
      setEventData(data?.data);
      // if(data?.data?.is_store === 0 && data?.data?.market_place === "0" &&( data?.data?.has_auction === "1" && data?.data?.has_buy_now === "1" && itemType === "event") ) setSearchParams(searchParams.delete('event-type','event'))
    } catch (error) {
    } finally {
      setIsEventLoading(false);
    }
  };

  const fetchCategoryById = async () => {
    try {
      setIsEventLoading(true);
      const { data } = await auctionServices.getCategoryById(
        categoryId,
        itemType
      );
      setCategoryData(data);
    } catch (error) {
    } finally {
      setIsEventLoading(false);
    }
  };

  const fetchEventDonors = async () => {
    try {
      if (!auctionId) return;
      setIsEventLoading(true);
      const data = await donorServices.getEventDonors(auctionId);
      const mappedData = data?.data?.map((item) => {
        return {
          id: item?.donor?.id,
          org_name: item?.donor?.org_name,
        };
      });
      setEventDonors(mappedData);
      setIsEventLoading(false);
    } catch (error) {
      setIsEventLoading(false);
    }
  };

  console.log(
    "event donors",
    selectedBrowseEventFilter,
    selectedBrowseEventFilterObj
  );

  useEffect(() => {
    // if (!auctionId) return
    if (
      [
        "event-byNow",
        "event-auction",
        "store",
        "marketplace",
        "event",
      ].includes(itemType) &&
      selectedCategory !== null
    ) {
      onCategoryClick(selectedCategory, categoryType);
    } else {
      if (groupBy !== "") {
        // && groupBy !== 'event'
        handleFilterApply();
      } else {
        /// for store initially this useEffect will not work because we need fot filter store by category first.
        // if(!(itemType === 'store' && groupBy === '')) {
        //     fetchData()
        // }
        if (groupBy === "") {
          fetchData();
        }
      }
    }
  }, [page, auctionId, selectedBrowseEventFilter]);

  // useEffect(() => {
  //     // this useEffect will work initially only for store
  //     if(!contextFilterd && (itemType === 'store' && groupBy === 'itemCategory')) {
  //         handleFilterApply()
  //     }
  // },[groupBy])

  useEffect(() => {
    if (auctionId) {
      fetchAuctionById();
      fetchEventDonors();
    }
    if (categoryId) {
      fetchCategoryById();
    }
    return () => {
      dispatch(resetBrowseItems());
      clearTimeout(scroller);
    };
  }, []);

  useEffect(() => {
    // To support old images whcih does not have variant(small,medium,large).
    const img = new Image();
    if (!eventData?.image2) return;
    img.src = eventData?.image2 + "_small";
    img.onerror = () => {
      setOldEventImage(true);
    };
    return () => {
      img.onerror = null;
      clearTimeout(timout);
    };
  }, [eventData?.image2, timout]);

  const toggleClearPage = () => {
    dispatch(changePageNumber(1));
  };

  const loadMore = (e, paginationPage) => {
    try {
      dispatch(changePageNumber(paginationPage));
      timout = setTimeout(
        () => window.scrollTo({ top: 400, behavior: "smooth" }),
        100
      );
    } catch (error) {
    } finally {
    }
  };

  const showEventHeading = () => {
    let heading = "";
    if (itemType === "event-byNow") {
      heading =
        eventData?.buy_now_label == "" ? `Shop` : eventData?.buy_now_label;
    } else {
      heading =
        eventData?.auction_label == "" ? `Auction` : eventData?.auction_label;
    }
    return heading;
  };

  const toggleFilterScreen = () => {
    if (clickedFilter === "filterby") {
      setIsFilterScreenOpen(!isFilterScreenOpen);
    } else if (clickedFilter === "sortby") {
      setIsSortByScreenOpen(!isSortByScreenOpen);
    }
  };

  useEffect(() => {
    toggleFilterScreen();
  }, [clickedFilter, filterChanging]);

  const toggleFilterChanging = () => {
    setFilterChanging(!filterChanging);
  };

  const goBack = () => {
    if (
      window.history.length <= 2 &&
      ![
        "event-byNow",
        "event-auction",
        "event-custom-category",
        "event-category",
      ].includes(itemType)
    ) {
      navigate("/shop-now", {
        preventScrollReset: true,
      });
    } else {
      navigate(-1, {
        preventScrollReset: true,
      });
    }
  };

  const handleFilterApply = async (stopLoading = false) => {
    try {
      setNoLoading(stopLoading);
      if (
        (groupBy === "" || groupBy === "event") &&
        itemType !== "browse-category" &&
        itemType !== "cause-category"
      ) {
        /// here we will just sort the items without grouping.
        /// here we will pass sort method only for sorting
        // toggleClearPage()
        fetchData();
        return;
      }
      dispatch(
        getGroupedBrowseItems({
          sortBy: sortBy,
          page: page,
          limit: limit,
          groupBy: groupBy,
          auctionId: auctionId,
          itemType: itemType,
          priceRangeStart: priceRange[0],
          priceRangeEnd: priceRange[1],
          categoryId: categoryId,
          userId: userId,
          adminPreview: adminPreview,
        })
      );
      setIsFilterScreenOpen(false);
      setIsSortByScreenOpen(false);
      setContextFilterd(true);
    } catch (error) { }
  };

  const getBannerImage = (item) => {
    let banner;
    if (item?.auction_item_assets) {
      banner = item?.auction_item_assets.find(
        (img) =>
          img.context_type === "thumbnail" || img.context_type === "portrait"
      )?.name;
    } else if (item?.assets) {
      banner = item?.assets.find(
        (img) =>
          img.context_type === "thumbnail" || img.context_type === "portrait"
      )?.name;
    }
    return banner;
  };

  const handleGoToEvent = () => {
    navigate(`/event/${auctionId}`, {
      state: {
        from: "event",
        admin_preview: eventData?.status === "building" ? true : false,
      },
    });
  };

  const handleGoToShop = () => {
    navigate("/shop-now");
  };

  const getHeaderColor = () => {
    switch (itemType) {
      case "event-auction":
      case "event-byNow":
        return mobileStyle.backgroundColor[2];
      case "marketplace":
      case "store":
      case "event-category":
      case "event-custom-category":
      case "browse-category":
      case "cause-category":
        return mobileStyle.backgroundColor[1];
      default:
        return mobileStyle.backgroundColor[2];
    }
  };

  const getCategoryDetails = () => {
    switch (itemType) {
      case "event-category":
      case "browse-category":
        return {
          image: require(`../../../../images/categoryIcons/1200x480/${categoryData?.image}`),
          name: categoryData?.name,
        };
      case "event-custom-category":
        return {
          image: oldEventImage
            ? getImageWithoutVariant(
              !forMobile ? eventData?.image2 : eventData?.image1
            )
            : getImageVariant(
              !forMobile ? eventData?.image2 : eventData?.image1
            ),
          name: categoryData?.category_name,
        };
      case "cause-category":
        return {
          image: require(`../../../../images/causeIcons/1200x480/${categoryData?.image}`),
          name: categoryData?.name,
        };
      default:
        break;
    }
  };

  //// category filtering start

  const setSlicedIndexs = (
    concatedCategory = [],
    totalLength = 60,
    setFinal = false
  ) => {
    let charLength = 0;
    let index = 0;
    for (let i = 0; i < concatedCategory?.length; i++) {
      charLength +=
        concatedCategory[i]?.category?.name?.length ||
        concatedCategory[i]?.custom_category?.category_name?.length;

      if (charLength > totalLength) {
        index = i;
        break;
      }
    }
    if (charLength > totalLength) {
      dispatch(clearAttribute({ key: "sliceIndex", value: index }));
      if (setFinal) {
        setSliceIndexFinal(index);
      } else {
        setSliceIndexInitial(index);
      }
    } else {
      if (setFinal) {
        dispatch(clearAttribute({ key: "sliceIndex", value: sliceIndexFinal }));
      }
    }
  };

  useEffect(() => {
    if (!auctionId) return;
    (async () => {
      try {
        setIsEventLoading(true);
        const categoryResponse = await fetchCategory();
        const customCategoryResponse = await fetchCustomCategoryHeading();
        let concatedCategory = [
          ...categoryResponse,
          ...customCategoryResponse,
        ].sort((a, b) => b.total_item_count - a.total_item_count);
        // setSlicedIndexs(concatedCategory, 50)
        setEventCategories(concatedCategory);
        setIsEventLoading(false);
      } catch (error) {
        setIsEventLoading(false);
      }
    })();
  }, []);

  const fetchCustomCategoryHeading = async () => {
    try {
      // setIsEventLoading(true)
      const data =
        await auctionServices.getCustomCategoryHeadingByAuctionIdHaveItem(
          auctionId,
          itemFlag,
          false,
          true
        ); ///false is for checking if heading is needed and true is for fetch categories group by name
      // setEventCustomCategories(data.data)
      // setIsEventLoading(false)
      return data?.data;
    } catch (error) {
      setIsEventLoading(false);
    }
  };
  const fetchCategory = async () => {
    try {
      // setIsEventLoading(true)
      // let data=await auctionServices.getAuctionCategoryList()
      const response = await auctionServices.getAuctionCategoryListHaveItem(
        auctionId,
        itemFlag
      );
      // setEventCategories(response.data)
      // setIsEventLoading(false)
      return response?.data;
    } catch (error) {
      setIsEventLoading(false);
    }
  };

  const handleCategoryClick = (item) => {
    // let catType = 'event-category';
    let fromType = "from-category";
    let categoryId = item?.category?.id;
    let cateogryName = item?.category?.name;
    if (item?.custom_category) {
      // catType = 'event-custom-category';
      fromType = "from-custom-category";
      categoryId = item?.custom_category?.id;
      cateogryName = item?.custom_category?.category_name;
    }
    toggleClearPage();
    dispatch(clearSearchText());
    dispatch(clearAttribute({ key: "customCategoryHeading", value: "" }));
    dispatch(
      setSelectedCategory({
        name: cateogryName,
        id: categoryId,
        categoryType: fromType,
      })
    );
    onCategoryClick(categoryId, fromType);
  };

  // const handleCategoryClick = (item) => {
  //     toggleClearPage()
  //     dispatch((clearSearchText()))
  //     dispatch(clearAttribute({key:'customCategoryHeading', value:''}))
  //     dispatch(setSelectedCategory({ name: item?.category?.name, id: item?.category?.id, categoryType: 'from-category' }))
  //     onCategoryClick(item?.category?.id, 'event-category')
  // }

  const onCategoryClick = async (catId, categoryType = "") => {
    try {
      if (!catId || catId === "backdropClick") return;
      setNoLoading(false);
      setIsEventLoading(true);
      let catItemType = "";
      switch (categoryType) {
        case "from-custom-category":
          catItemType = "event-custom-category";
          break;
        case "from-category":
          catItemType = "event-category";
          break;
        default:
          catItemType = "event-category";
      }
      // dispatch(clearAttribute({key:'sliceIndex', value:sliceIndexInitial}))
      dispatch(clearAttribute({ key: "showSearchBar", value: false }));
      dispatch(
        getBrowseItemsProducts({
          auctionId: auctionId,
          page: page,
          limit: limit,
          sortBy: sortBy,
          priceRangeStart: priceRange[0],
          priceRangeEnd: priceRange[1],
          itemType: catItemType,
          categoryId: catId,
          itemFlag: itemFlag,
          userId: userId,
          adminPreview: adminPreview,
        })
      );
      setIsEventLoading(false);
      setIsFilterScreenOpen(false);
      setIsSortByScreenOpen(false);
      setContextFilterd(true);
    } catch (error) {
      setIsEventLoading(false);
    }
  };

  const handelAllClicked = () => {
    setNoLoading(false);
    dispatch(resetItems());
    dispatch(clearSearchText());
    dispatch(clearAllBrowseBySelectedFilters());
    dispatch(clearAttribute({ key: "customCategoryHeading", value: "All" }));
    // reset()
    if (itemType === "store") {
      setGroupBy("itemCategory");
      setSortBy("Recommended");
    } else {
      reset();
    }
    toggleClearPage();
    if (page === 1) {
      // fetchData()
      if (itemType === "store") {
        handleFilterApply();
      } else {
        dispatch(
          getBrowseItemsProducts({
            auctionId: auctionId,
            page: page,
            limit: limit,
            sortBy: sortBy,
            priceRangeStart: priceRange[0],
            priceRangeEnd: priceRange[1],
            itemType: itemType,
            categoryId: categoryId,
            userId: userId,
            adminPreview: adminPreview,
          })
        );
      }
    }
    setIsFilterScreenOpen(false);
    setIsSortByScreenOpen(false);
    setContextFilterd(true);
  };

  const handleSearchChange = (e) => {
    dispatch(setSearchText(e.target.value));
  };

  const onIconClick = () => {
    const event = {
      target: {
        value: searchText,
      },
      key: "Enter",
    };
    onSearchClick(event);
  };

  const onSearchClick = (e) => {
    if (e.key === "Enter" && e.target.value.length >= 2) {
      dispatch(clearCategoryDetails());
      dispatch(setContextFrom(null));
      if (itemType === "store") {
        setGroupBy("itemCategory");
        setSortBy("Recommended");
      } else {
        reset();
      }
      toggleClearPage();
      if (page === 1) {
        fetchData();
      }
    }
  };

  /// category filtering end

  const getSortByUiDetails = () => {
    switch (itemType) {
      case "event-byNow":
      case "event-auction":
      case "store":
        return {
          className: "width_class",
        };
      case "marketplace":
      case "event":
        return {
          className: "marketplace_width_class",
        };
      default:
        return {
          className: "full_width_class",
        };
    }
  };

  const handleSliceIndex = () => {
    // dispatch(clearAttribute({key:'sliceIndex', value:sliceIndexFinal}))
    // setSlicedIndexs(eventCategories, 44, true)///true is for seting final
    dispatch(clearAttribute({ key: "showSearchBar", value: !showSearchBar }));
  };

  const checkCallFilter = () => {
    if (["itemCategory"].includes(groupBy)) {
      return true;
    } else return false;
  };

  const scrollFilters = () => {
    if (scrollContainerRef.current)
      scrollContainerRef.current.scrollLeft += 150;
  };

  const handleClearRemoveFromFilter = (value, arrayType) => {
    dispatch(clearBrowseBySelectedFilters({ key: arrayType, value: value }));
  };
  const getshowDetailsOverBanner = () => {
    if (
      itemType === "event-byNow" &&
      !eventData?.show_details_over_store_page_banner
    )
      return false;
    if (
      itemType === "event-auction" &&
      !eventData?.show_details_over_auction_page_banner
    )
      return false;
    return true;
  };

  // console.log(groupBy, 'groupby at browseitems')

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const styleLarge = {
    position: 'absolute',
    top: '55%',
    left: '80%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #30313A29',
    boxShadow: 24,
    // p: 4,
  };

  const styleSmall = {
    position: 'absolute',
    top: '35%',
    left: '40%',
    transform: 'translate(-40%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '1px solid #30313A29',
    boxShadow: 24,
    // p: 4,
  };

  const isSmallScreen = mdq('(max-width:600px)');

  const style = isSmallScreen ? styleSmall : styleLarge;

  const handleScroll = () => {
    const middleComponent = document.querySelector('.header_component');
    if (middleComponent) {
      const rect = middleComponent.getBoundingClientRect();
      // Check if the middle component is out of view (above the viewport)
      if (rect.bottom < -10) {
        setShowNewTimer(true);
      } else {
        setShowNewTimer(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return isEventLoading ? (
    <LoadingScreen bgColor="#F0E8E3" />
  ) : (
    <>
      <Helmet prioritizeSeoTags>
        {/* <title>Browse Event | Boundless Giving</title> */}
        <title>
          {[
            "store",
            "marketplace",
            "event",
            "event-byNow",
            "event-auction",
            "event-category",
            "event-custom-category",
          ].includes(itemType)
            ? eventData?.title
            : "Browse Items"}
        </title>
        <meta
          name="description"
          content={
            [
              "store",
              "marketplace",
              "event",
              "event-byNow",
              "event-auction",
              "event-category",
              "event-custom-category",
            ].includes(itemType)
              ? eventData?.about
              : ""
          }
        />
        <link
          rel="canonical"
          href={`${REACT_APP_URL}${window.location.pathname}${window.location.search}`}
        />
      </Helmet>

      <div>
        <div
          className="browse-page"
          style={{
            minHeight: forMobile ? "100vh" : "",
            backgroundColor: backgroundColor,
            justifyContent: forMobile ? "space-between" : "",
          }}
        >
          {/* <HeaderV2
                            primaryBackgroundColor={mobileStyle.backgroundColor[2]}
                            primaryColor={getHeaderColor()}
                            containerStyle={{ position: 'fixed' }}
                            transparentNav
                        /> */}
          <HeaderV3 />

          {/* {itemType === "event-byNow" || itemType === "event-auction" ? (
            <BrowseItemsBanner
              image={
                oldEventImage
                  ? getImageWithoutVariant(
                      !forMobile ? eventData?.image2 : eventData?.image1
                    )
                  : getImageVariant(
                      !forMobile ? eventData?.image2 : eventData?.image1
                    )
              }
              heading={showEventHeading()}
              title={eventData?.title}
              primaryColor={mobileStyle.color[2]}
              about={eventData?.about}
              gradientClass={
                "mp_desktop_gradient_section_buynow_and_auction" +
                " " +
                "mp-banner-text-box-gradient"
              }
              fromEvent={true}
              params={eventData}
              showIncomplete={showIncomplete}
              bannerTextClass="mp-banner-text-event-auction-and-buynow"
              imageStyle={{ height: "100%" }}
              showDetailsOverBanner={getshowDetailsOverBanner()}
            />
          ) : Object.keys(categoryData).length &&
            [
              "event-category",
              "event-custom-category",
              "browse-category",
              "cause-category",
            ].includes(itemType) ? (
            <BrowseItemsBanner
              image={getCategoryDetails().image}
              heading={getCategoryDetails().name}
              primaryColor={mobileStyle.color[1]}
              gradientClass="overlay_white_gradient"
              showIncomplete={showIncomplete}
              imageStyle={{ height: "100%" }}
            />
          ) : (
            <ESMBannerPart
              eventData={eventData}
              image={
                oldEventImage
                  ? getImageWithoutVariant(
                      !forMobile ? eventData?.image2 : eventData?.image1
                    )
                  : getImageVariant(
                      !forMobile ? eventData?.image2 : eventData?.image1
                    )
              }
              itemType={itemType}
              showIncomplete={showIncomplete}
              showDetailsOverBanner={eventData?.show_details_over_banner === 1}
            />
          )} */}
          {eventData?.show_details_over_banner === 0 &&
            ["store", "marketplace", "event"].includes(itemType) && (
              <EventPageDetailsCard
                eventData={eventData}
                showIncomplete={adminPreview}
                itemType={itemType}
              />
            )}
          <div className={classes.event_header}>
            <div className={`${classes.event_main_title} header_component`}>
              <div className={classes.event_main_2}>
                <div className={`${classes.event_title}`}>{eventData.title}</div>

                <div className={classes.product_count} style={{ borderLeft: "1px #30313A29 solid", padding: "5px", margin: "0 5px", color: "#65666E" }}>{'Showing '}{totalCount} {`${totalCount > 1 ? "products" : "product"}`}</div>
              </div>
              {/* <div className={classes.filters_buttons_container}>
                  <button
                    className="filter-btn"
                    onClick={() => {
                      toggleFilterChanging();
                      setClickedFilter("filterby");
                    }}
                    style={{border: '1px solid #30313A29',borderRadius: '980px',padding:' 10px 12px'}}
                  >
                    <span className="filter-btn__text">Filter By</span>
                    <img
                      src={downArrow}
                      style={{
                        marginLeft: "5px",
                        transform: isFilterScreenOpen && "rotate(180deg)",
                      }}
                      alt="Arrow"
                    />
                  </button> */}
              {/* <button
                    className="filter-btn"
                    onClick={() => {
                      toggleFilterChanging();
                      setClickedFilter("sortby");
                    }}
                    style={{border: '1px solid #30313A29',borderRadius: '980px',padding:' 10px 12px'}}
                  >
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.1667 3.83331V17.1666M14.1667 17.1666L10.8333 13.8333M14.1667 17.1666L17.5 13.8333M5.83333 17.1666V3.83331M5.83333 3.83331L2.5 7.16665M5.83333 3.83331L9.16667 7.16665" stroke="#30313A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span className="filter-btn__text" style={{color:"black !important"}}>SORT</span>

                  </button> */}
              {/* </div> */}
              <div className={classes.button_container} style={{ display: "flex", alignItems: 'center', gap: "10px" }}>

                <div className={classes.product_count_2} style={{ borderLeft: "1px #30313A29 solid", padding: "5px", margin: "0 5px", color: "#65666E" }}>{'Showing '}{totalCount} {`${totalCount > 1 ? "products" : "product"}`}</div>

                <div className={classes.filter_button}>
                  <Button onClick={() => {
                    handleOpen();
                    toggleFilterChanging();
                    setClickedFilter("filterby");
                  }}
                    style={{ border: '1px solid #30313A29', borderRadius: '980px', padding: ' 10px 12px' }}
                  >
                    <div style={{ display: "flex", gap: "4px" }}>
                      <span className="filter-btn__text">FILTER</span>
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 10.5H15M2.5 5.5H17.5M7.5 15.5H12.5" stroke="#30313A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>


                  </Button>
                </div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <FilterScreen
                      sortBy={sortBy}
                      //   handleSorting={}
                      setSortBy={setSortBy}
                      //   sortByRef={}
                      groupBy={groupBy}
                      setGroupBy={setGroupBy}
                      itemType={itemType}
                      priceRange={priceRange}
                      handlePriceChange={handlePriceChange}
                      handleFilterApply={handleFilterApply}
                      toggleClearPage={toggleClearPage}
                      filterState={clickedFilter}
                      eventCategories={eventCategories}
                      eventCustomCategories={eventCustomCategories}
                      onCategoryClick={onCategoryClick}
                      handelAllClicked={handelAllClicked}
                      handleClose={handleClose}
                    />
                  </Box>
                </Modal>


                <Button onClick={() => {
                  handleOpen(); toggleFilterChanging();
                  setClickedFilter("sortby");
                }} style={{ border: '1px solid #30313A29', borderRadius: '980px', padding: ' 10px 12px' }}>
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.1667 3.83331V17.1666M14.1667 17.1666L10.8333 13.8333M14.1667 17.1666L17.5 13.8333M5.83333 17.1666V3.83331M5.83333 3.83331L2.5 7.16665M5.83333 3.83331L9.16667 7.16665" stroke="#30313A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span className="filter-btn__text" style={{ color: "black !important" }}>SORT</span>
                </Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <FilterScreen
                      sortBy={sortBy}
                      //   handleSorting={}
                      setSortBy={setSortBy}
                      //   sortByRef={}
                      groupBy={groupBy}
                      setGroupBy={setGroupBy}
                      itemType={itemType}
                      priceRange={priceRange}
                      handlePriceChange={handlePriceChange}
                      handleFilterApply={handleFilterApply}
                      toggleClearPage={toggleClearPage}
                      filterState={clickedFilter}
                      eventCategories={eventCategories}
                      eventCustomCategories={eventCustomCategories}
                      onCategoryClick={onCategoryClick}
                      handelAllClicked={handelAllClicked}
                      handleClose={handleClose}
                    />
                  </Box>
                </Modal>


              </div>
            </div>
            <div className={classes.countdown_container} style={{ padding: '5px' }}>
              {showNewTimer ? (
                <CountdownTimerScroll endTime={eventData?.end_date} title={eventData.title} totalCount={totalCount} discountPercentage={eventData?.discount_percentage}/>
              ) : (
                <CountdownTimer endTime={eventData?.end_date} discountPercentage={eventData?.discount_percentage}/>
              )}
            </div>

          </div>


          <div
            className={classes.margin_class}
            style={{
              backgroundColor: backgroundColor,
              marginTop:
                eventData?.show_details_over_banner === 0 &&
                  ["store", "marketplace", "event"].includes(itemType)
                  ? "25"
                  : "0px",
            }}
          >
            {
              !isTablet &&
              [
                "event-byNow",
                "event-auction",
                "marketplace",
                "event",
                "store",
              ].includes(itemType) && (
                // <Mobile>
                <div className={classes.search_section}>
                  <SearchInput
                    onChange={handleSearchChange}
                    onClick={onSearchClick}
                    value={searchText}
                    onIconClick={onIconClick}
                    outlinedInputStyle={{ padding: "5px 12px" }}
                  />
                </div>
              )
              // </Mobile>
            }
            <div
              className={`back-container items-control ${classes.back_container}`}
            >
              <button className={classes.go_back_button}>
                <span onClick={goBack}>
                  <ChevronLeft
                    style={{
                      color: "rgba(252, 51, 0, 1)",
                      fontSize: forMobile ? "1rem" : "1.5rem",
                    }}
                  />
                  {totalCount} {`item${totalCount > 1 ? "s" : ""}`}
                </span>
                {/* breadCrumbs */}
                {[
                  "store",
                  "marketplace",
                  "event",
                  "event-byNow",
                  "event-auction",
                ].includes(itemType) && (
                    <span className={classes.bread_crumb_container}>
                      {(customCategoryHeading !== "" || categoryName !== "") && (
                        <p>
                          <ChevronRight
                            sx={{ fontSize: forMobile ? "1rem" : "1.5rem" }}
                          />
                        </p>
                      )}
                      {customCategoryHeading !== "" && (
                        <p>
                          {customCategoryHeading}{" "}
                          <ChevronRight
                            sx={{ fontSize: forMobile ? "1rem" : "1.5rem" }}
                          />{" "}
                        </p>
                      )}
                      {categoryName !== "" && (
                        <p className={classes.category_crumb}>{categoryName}</p>
                      )}
                    </span>
                  )}
              </button>
              {/* <div className={classes.filters_buttons_container}>
                <button
                  className="filter-btn"
                  onClick={() => {
                    toggleFilterChanging();
                    setClickedFilter("filterby");
                  }}
                >
                  <span className="filter-btn__text">Filter By</span>
                  <img
                    src={downArrow}
                    style={{
                      marginLeft: "5px",
                      transform: isFilterScreenOpen && "rotate(180deg)",
                    }}
                    alt="Arrow"
                  />
                </button> */}
              {/* {
                    ['store', 'marketplace', 'event-byNow', 'event-auction'].includes(itemType) &&
                    <Mobile>
                      <button className="filter-btn" onClick={() => {
                        toggleFilterChanging();
                        setClickedFilter('sortby');
                      }}>
                        <span className="filter-btn__text">Sort By</span>
                        <img
                          src={downArrow}
                          style={{ marginLeft: '5px', transform: isSortByScreenOpen && 'rotate(180deg)' }}
                          alt="Arrow"
                        />
                      </button>
                    </Mobile>
                  } */}
              {/* </div> */}
            </div>

            {/* Sorting section start */}

            {/* <Desktop> */}
            {[
              "event-byNow",
              "event-auction",
              "marketplace",
              "event",
              "store",
            ].includes(itemType) ? (
              <div className={classes.sortby_container}>
                <div
                  ref={scrollContainerRef}
                  className={`${classes.sortby_section} ${classes[getSortByUiDetails()?.className]
                    }`}
                >
                  <NewButton
                    onClick={handelAllClicked}
                    className="browse_sort_by_class"
                    text={"All"}
                    style={{
                      backgroundColor:
                        customCategoryHeading === "All" &&
                        "rgba(48, 49, 58, 0.25)",
                    }}
                  />
                  {/* mapping custom categories */}
                  {/* {
                                                eventCustomCategories?.map((item, index) => {
                                                    return (
                                                        <CustomCategoryDropdown
                                                            onCustomCategoryChange={onCategoryClick}
                                                            key={index}
                                                            customCategory={item?.auction_custom_categories}
                                                            customCategoryHeading={item?.name}
                                                        />
                                                    )
                                                })
                                            } */}
                  {
                    // mapping categories
                    eventCategories.map((item, index) => {
                      return (
                        <NewButton
                          onClick={() => handleCategoryClick(item)}
                          key={index}
                          className="browse_sort_by_class"
                          text={
                            item?.category?.name
                              ? item?.category?.name
                              : item?.custom_category?.category_name
                          }
                          style={{
                            backgroundColor:
                              (selectedCategory === item?.category?.id ||
                                selectedCategory ===
                                item?.custom_category?.id) &&
                              "rgba(48, 49, 58, 0.25)",
                          }}
                        />
                      );
                    })
                  }
                  {/* {
                                                eventCategories?.slice(sliceIndex, eventCategories?.length).length ?
                                                <CustomCategoryDropdown
                                                   onCustomCategoryChange={onCategoryClick}
                                                //    key={index}
                                                   customCategory={eventCategories?.slice(sliceIndex, eventCategories?.length)}
                                                   customCategoryHeading={'More'}
                                                />
                                                :null
                                            } */}
                </div>
                <Mobile>
                  <span className={classes.rightArrow}>
                    <KeyboardArrowRight onClick={scrollFilters} />
                  </span>
                </Mobile>
                {["event-byNow", "event-auction", "store"].includes(itemType) &&
                  eventDonors?.length ? (
                  <BrowseByDropDown
                    filterArray={eventDonors}
                    filterType={"brandsFilter"}
                    dropDownText={"BRANDS"}
                    selectedFilters={selectedBrowseEventFilter}
                    pageType="event-browse-items"
                  />
                ) : null}
                {/* <Desktop> */}
                {isTablet && (
                  <div className={classes.search_section}>
                    <SearchInput
                      onChange={handleSearchChange}
                      onClick={onSearchClick}
                      value={searchText}
                      onIconClick={onIconClick}
                      outlinedInputStyle={{ padding: "5px 12px" }}
                    />
                  </div>
                )}

                {/* <span onClick={handleSliceIndex} className={classes.search_icon} ><Search/></span> */}
                {/* </Desktop> */}
              </div>
            ) : null}
            {/* </Desktop> */}

            {/* Sorting section end */}

            {selectedBrowseEventFilterObj?.brands?.length ? (
              <div className={classes.filterdOutputs}>
                {selectedBrowseEventFilterObj?.brands?.map((obj) => (
                  <div key={obj.id} className={classes.outPut}>
                    <span className={classes.outSpan}>{obj?.name}</span>{" "}
                    <CloseRounded
                      className={classes.clearSingleIcon}
                      onClick={() => handleClearRemoveFromFilter(obj, "brands")}
                    />
                  </div>
                ))}
              </div>
            ) : null}

            <BrowsePagesCardSection
              primaryBackgroundColor={backgroundColor}
              isLoading={loading && !noLoading}
            >
              {itemList?.length ? (
                <div
                  className={`dbrowse-items-list ${classes.items_list_main}`}
                >
                  {itemList[0].items?.length ? (
                    itemList?.map((groupObj, idx) => (
                      <div key={idx}>
                        <h1 className={classes.group_name}>{groupObj?.name}</h1>
                        <hr />
                        <div className="browse-items">
                          {groupObj?.items?.map((item, index) => (
                            <NewProductCardForFlashSale
                              key={index}
                              item={item}
                            />
                            // <BrowseCard key={index} {...item} banner={getBannerImage(item)} status={item?.status} needXref={auctionId?true:false} handleFilterApply={checkCallFilter() && handleFilterApply} updateFavourite={checkCallFilter()} />
                          ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      <div>
                        {categoryName !== "" && selectedCategory !== null && (
                          <>
                            <h1 className={classes.group_name}>
                              {categoryName}
                            </h1>
                            <hr />
                          </>
                        )}
                      </div>

                      <div className="browse-items">
                        {itemList?.map((item, index) => (
                          <NewProductCardForFlashSale
                            item={item}
                            key={index}
                            image={
                              item?.assets?.find(
                                (img) => img.context_type === "portrait"
                              )?.name
                            }
                          />
                          // <BrowseCard key={index} {...item} banner={getBannerImage(item)} status={item?.status} needXref={auctionId?true:false} />
                        ))}
                      </div>
                    </>
                  )}
                  {/* {(itemList?.length > 0) ? (<div className={classes.old_pagination} >
                                    
                                            {!loading && <Pagination
                                                count={totalPage}
                                                onChange={loadMore}
                                                page={page}
                                            />}
                                        </div>) : null} */}
                  {itemList?.length > 0 ? (
                    <PaginationV2
                      totalPage={totalPage}
                      page={page}
                      onLoadMore={loadMore}
                    />
                  ) : null}
                </div>
              ) : (
                <div className={`no-data ${classes.no_data}`}>Coming Soon!</div>
              )}
            </BrowsePagesCardSection>
            {auctionId && itemType !== "marketplace" && (
              <BrowseItemsCauseSection causeData={eventData?.causeDetails} />
            )}
            <Desktop>
              <div className={classes.return_buttons}>
                {[
                  "event-category",
                  "event-custom-category",
                  "event-byNow",
                  "event-auction",
                ].includes(itemType) ? (
                  <p className={classes.return_event}>
                    <button
                      onClick={handleGoToEvent}
                      className={`to_top_btn ${classes.return_event_button}`}
                    >
                      Return to Event
                    </button>
                  </p>
                ) : null}
                {itemType !== "event" && (
                  <p className={classes.return_event}>
                    <button
                      onClick={handleGoToShop}
                      className={`to_top_btn ${classes.return_event_button}`}
                    >
                      Return to Boundless Shop
                    </button>
                  </p>
                )}
              </div>
            </Desktop>
          </div>
        </div>
      </div>
      <NewFooter
        primaryBackgroundColor={mobileStyle.backgroundColor[1]}
        primaryColor={mobileStyle.color[2]}
        logoColor={mobileStyle.color[0]}
      />
      {eventData?.status === "building" && (
        <AdminPreviewBanner type={itemType} title={eventData?.title} />
      )}
      {/* <SwipeableDrawer
        anchor="left"
        open={isFilterScreenOpen || isSortByScreenOpen}
        onClose={toggleFilterScreen}
        onOpen={toggleFilterScreen}
        sx={{ paddingInline: "30px" }}
      >
        <FilterScreen
          sortBy={sortBy}
          //   handleSorting={}
          setSortBy={setSortBy}
          //   sortByRef={}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          itemType={itemType}
          priceRange={priceRange}
          handlePriceChange={handlePriceChange}
          handleFilterApply={handleFilterApply}
          toggleClearPage={toggleClearPage}
          filterState={clickedFilter}
          eventCategories={eventCategories}
          eventCustomCategories={eventCustomCategories}
          onCategoryClick={onCategoryClick}
          handelAllClicked={handelAllClicked}
        />
      </SwipeableDrawer> */}
    </>
  );
};

export default EventFlashSales;
