import React, { useState, useEffect } from "react";
import moment from "moment";
import classess from "./CountDownScroll.module.css";

export default function CountdownTimerScroll({ endTime, title, totalCount }) {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = moment();
      const end = moment(endTime);
      const duration = moment.duration(end.diff(now));

      if (duration.asSeconds() <= 0) {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        setTimeLeft({
          days: Math.floor(duration.asDays()),
          hours: duration.hours(),
          minutes: duration.minutes(),
          seconds: duration.seconds(),
        });
      }
    };

    calculateTimeLeft();

    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [endTime]);

  const endDate = moment(endTime);
  const dayOfWeek = endDate.format("dddd").toUpperCase();

  const isLessThan7Days = timeLeft.days < 7;
  const formattedDate = endDate.format("MMMM Do");

  return (
    <div className={classess.countdown_container}>
      <div className={classess.countdown_title}>
        <h3 className={classess.title_main}>{title}</h3>
      </div>
      <div className={classess.item_count} style={{ borderLeft: "1px #30313A29 solid",borderRight: "1px #30313A29 solid", padding: "10px", margin: "0 5px", color: "#65666E" }}>
        {'Showing '}{totalCount} {`${totalCount > 1 ? "products" : "product"}`}
      </div>
      <div className={classess.countdown_item}>
        <div className={classess.countdown_value}>ENDS<br/>{dayOfWeek}</div>
      </div>
      <div className={classess.countdown_timer}>
        <div className={classess.countdown_item}>
          <div className={classess.countdown_value}>
            {String(timeLeft.days).padStart(2, "0")}
          </div>
          <div className={classess.countdown_label}>[DAYS]</div>
        </div>
        <div className={classess.countdown_item}>
          <div className={classess.countdown_value}>
            {String(timeLeft.hours).padStart(2, "0")}
          </div>
          <div className={classess.countdown_label}>[HRS]</div>
        </div>
        <div className={classess.countdown_item}>
          <div className={classess.countdown_value}>
            {String(timeLeft.minutes).padStart(2, "0")}
          </div>
          <div className={classess.countdown_label}>[MIN]</div>
        </div>
        <div className={classess.countdown_item}>
          <div className={classess.countdown_value}>
            {String(timeLeft.seconds).padStart(2, "0")}
          </div>
          <div className={classess.countdown_label}>[SEC]</div>
        </div>
      </div>
    </div>
  );
}
