import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classes from './NewProductCard.module.css'
import TagHeading from '../../atoms/TagHeading/TagHeading'
import ImageSkelton from '../ImageSkelton/ImageSkelton';
import { getDiscount, getDisplayListPrice, getDisplayPrice } from '../../../../utils/displayPrice';


const getCauseAmount = (item) => {
    let amt = 0;
    const causeSharePercentage = (item?.donor?.revenue_splits ? (+item?.donor?.revenue_splits[0]?.cause_rev_share_percent / 100) || 0.8 : 0.8)
    if (item?.store_item_id) {
        amt = ( (Number(getDisplayPrice(item))-Number(getDiscount(item))) * causeSharePercentage);
    }
    else {
        if (item?.highestBid?.length) {
            amt = Number(item?.highestBid[0]?.max_bid * causeSharePercentage);
        }
        else if (item?.buy_now_price) {
            amt = Number(item?.buy_now_price * causeSharePercentage);
        }
        else {
            amt = Number(item?.price * causeSharePercentage);
        }
    }
    return amt;
};


const CardBottom = ({ item }) => {
    let price = Number(getDisplayPrice(item)).toFixed(2)
    let listPrice = getDisplayListPrice(item)
    if (item?.store_item_id) {
        return (
            <div className={classes.priceContainer} >
                {
                    getDisplayListPrice(item) &&
                    <span className={classes.listPriceContainerStrike} >{
                        price - listPrice === 0 ? null : `${listPrice} Retail`
                    }</span>
                }
                <div className={classes.listPriceContainer} >
                    <span className={classes.itemPriceValue} >${price}</span>
                    <span className={classes.itemPriceLabel}>Boundless Price</span>
                </div>
                {
                    item?.store_item?.quantity_in_stock < 5 && item?.store_item?.quantity_in_stock > 0 && item?.show_stock_availability === 1 &&
                    <div className={classes.itemRedAlert}>
                        <span className='red_alert'>only {item?.store_item?.quantity_in_stock} left</span>
                    </div>
                }
                {
                    item?.store_item?.quantity_in_stock === 0 && item?.show_stock_availability === 1 &&
                    <div className={classes.itemRedAlert}>
                        <span className='red_alert'>Sold Out</span>
                    </div>
                }
                {
                    item?.auction?.status === 'complete' &&
                    <div className={classes.itemRedAlert}>
                        <span className='red_alert'>Event Closed</span>
                    </div>
                }
                { item?.store_item?.quantity_in_stock !== 0 && item?.store_item &&
                ( (item?.auction_auction_item_xrefs?.[0]?.auction?.discount_percentage || item?.auction?.discount_percentage) &&
                    <div className={classes.itemRedAlert}>
                        [Additional {item?.auction_auction_item_xrefs?.[0]?.auction?.discount_percentage
                                        ? item?.auction_auction_item_xrefs[0].auction.discount_percentage
                                        : item?.auction?.discount_percentage}% off given at checkout]
                    </div>
                )
                }
            </div>
        )
    }
    return (
        <div className={classes.priceContainer} >
            {
                item?.highestBid?.length ?
                    <div className={classes.listPriceContainer} >
                        <span className={classes.itemPriceValue} >${item?.highestBid[0]?.bid_amount}</span>
                        <span className={classes.itemPriceLabel}>Current Bid</span>
                    </div>
                    :
                    <div className={classes.listPriceContainer} >
                        <span className={classes.itemPriceValue} >${item?.price}</span>
                        <span className={classes.itemPriceLabel}>Opening Bid</span>
                    </div>
            }
            {
                item?.buy_now_price &&
                <div className={classes.listPriceContainer} >
                    <span className={classes.itemPriceValue} >${item?.buy_now_price}</span>
                    <span className={classes.itemPriceLabel}>Buy Now</span>
                </div>
            }
            {
                item?.status === 'sold' && item?.show_stock_availability === 1 &&
                <div className={classes.itemRedAlert}>
                    <span className='red_alert'>Sold</span>
                </div>
            }
            {
                item?.auction?.status === 'complete' &&
                <div className={classes.itemRedAlert}>
                    <span className='red_alert'>Event Closed</span>
                </div>
            }
        </div>
    )
}

const NewProductCard = ({ item, image, imageContainerClass = '', customItemNameClass = '', cardContainerClass = '', needXref = true }) => {
    const navigate = useNavigate()
    const elemRef = useRef()
    const [imageLoading, setImageLoading] = useState(true)
    const handleNavigateClick = () => {
        // navigate(`/lot/${item?.id}?auction_id=${item?.auction_id}&cause_id=${item?.auction?.cause_id}`)
        if (elemRef.current) {
            localStorage.setItem("browseScroll", elemRef.current.offsetTop - elemRef.current.scrollTop)
        }
        let xrefAuctionId;
        let cause_id = item?.auction?.cause_id;
        if (item?.auction_auction_item_xrefs?.length && needXref) {
            xrefAuctionId = item?.auction_auction_item_xrefs[0]?.auction_id;
            cause_id = item?.auction_auction_item_xrefs[0]?.auction?.cause?.id
            navigate(`/lot/${item?.id}?auction_id=${item?.auction_id}&cause_id=${cause_id}&xref_auction_id=${xrefAuctionId}`)
        } else {
            navigate(`/lot/${item?.id}?auction_id=${item?.auction_id}&cause_id=${cause_id}`)
        }
    }

    return (
        <div ref={elemRef} onClick={handleNavigateClick} className={`${classes.productCardContainer} ${classes[cardContainerClass]}`} >
            <div className={`${classes.imageContainer} ${classes[imageContainerClass]}`} >
                <img src={image || (item?.auction_item_assets?.find(img => img.context_type === 'portrait')?.name)} alt="" onLoad={() => setImageLoading(false)} style={{ display: imageLoading ? "none" : 'block' }} />
                <ImageSkelton loadingState={imageLoading} style={{ height: '100%', aspectRatio: 'auto' }} />
                {
                    item?.auction_item_amount_given_dialog === 1 &&
                    <div className={classes.donationBox} >
                        <TagHeading heading='DONATION IMPACT' customClass='donationLabel' />
                        <h2 className={classes.donationValue}>${getCauseAmount(item).toFixed(2)}</h2>
                    </div>
                }
            </div>
            <div className={classes.cardContent}>
                {item?.auction?.market_place == '1' && <TagHeading heading={item?.auction?.title} customClass='featuredItemCard' />}
                <h2 className={`newTitleClass ${classes.itemName} ${classes[customItemNameClass]}`}>{item?.name}</h2>
            </div>
            <CardBottom item={item} />
        </div>
    )
}

export default NewProductCard;